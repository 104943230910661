import React from 'react';

import { MenuLayout } from '../components/menu-layout.component';
import { Footer } from '../components/footer.component';

import Feature1 from '../assets/svgs/pcr-features/feature1.svg';
import Feature2 from '../assets/svgs/pcr-features/feature2.svg';
import Feature3 from '../assets/svgs/pcr-features/feature3.svg';
import Feature4 from '../assets/svgs/pcr-features/feature4.svg';
import Feature5 from '../assets/svgs/pcr-features/feature5.svg';
import Feature6 from '../assets/svgs/pcr-features/feature6.svg';
import Situation1 from '../assets/svgs/pcr-features/situation1.svg';
import Situation2 from '../assets/svgs/pcr-features/situation2.svg';
import Situation3 from '../assets/svgs/pcr-features/situation3.svg';

export default function Faqs() {
    return (
        <MenuLayout disableSecureLayout={true} isAbs={true} className="homepage transparent">
            <section className="features__hero">
                <div className="container">
                    <h1 className="t-h2 features__hero__heading">当法人のPCR検査の特徴</h1>
                    <div className="features-block t-sans">
                        <div className="feature">
                            <div className="feature__icon"><Feature1 /></div>
                            <div className="feature__detail">
                                <h3 className="feature__heading t-green">どこでも審査</h3>
                                <p className="feature__body t-p1 t-grey7">PCR検査キットを郵送しますので、ご自宅や職場など場所を問わずどこでも唾液採取いただけます。</p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="feature__icon"><Feature2 /></div>
                            <div className="feature__detail">
                                <h3 className="feature__heading t-green">簡単返送</h3>
                                <p className="feature__body t-p1 t-grey7">当法人が使用している検査キットは、採取後に検体を冷蔵する必要はありません。同封しているレターパックに梱包し、ポストへ投函してください。</p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="feature__icon"><Feature3 /></div>
                            <div className="feature__detail">
                                <h3 className="feature__heading t-green">痛くない</h3>
                                <p className="feature__body t-p1 t-grey7">どなたでも簡単に採取が可能な、唾液採取となります。鼻咽頭拭い液の採取（鼻やのどから長い綿棒を入れての採取）とは違い、痛みや不快感はありません。
                                    <br/><span className="smaller">＊<a className="link t-grey7" href="https://www.mhlw.go.jp/content/10906000/000635988.pdf" target="_blank">厚生労働科学研究</a>において「発症から9日以内の症例では、鼻咽頭ぬぐい液と唾液との結果に高い一致率」が認められております。</span></p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="feature__icon"><Feature4 /></div>
                            <div className="feature__detail">
                                <h3 className="feature__heading t-green">結果はメールでお知らせ</h3>
                                <p className="feature__body t-p1 t-grey7">検査結果は、返送された検体が検査室に到着後、最短24時間または72時間以内にメールにてご報告させていただきます（クイック検査では、検体到着後24時間以内にご報告いたします。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features__ifpositive">
                <div className="container">
                    <h2 className="features__ifpositive__heading t-heading1 t-h2">発生届対象者が陽性となった場合</h2>
                    <div className="positive-situations t-sans">
                        <div className="positive-situation">
                            <Situation1 />
                            <h3 className="positive-situation__heading t-green">電話診療</h3>
                            <p className="positive-situation__body t-p1 t-grey7">陽性の場合、グループの医師が直接お電話し新型コロナウイルス感染症の症状などを確認いたします</p>
                        </div>
                        <div className="positive-situation-divider"></div>
                        <div className="positive-situation">
                            <Situation2 />
                            <h3 className="positive-situation__heading t-green">保健所へ届出</h3>
                            <p className="positive-situation__body t-p1 t-grey7">症状の確認後、迅速に保健所へ連絡いたします</p>
                        </div>
                        <div className="positive-situation-divider"></div>
                        <div className="positive-situation">
                            <Situation3 />
                            <h3 className="positive-situation__heading t-green">保健所から指示</h3>
                            <p className="positive-situation__body t-p1 t-grey7">保健所から連絡がきますので、指示に従ってください</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features__usecases">
                <div className="container">
                    <h2 className="features__usecases__heading t-h2">このような時に利用されています</h2>
                    <div className="usecases">
                        <div className="usecase">
                            <img src="/img/features/case1.jpg" alt="use case" />
                            <div className="usecase__detail">
                                <h3 className="usecase__heading t-serif t-h3">無症状なものの、感染が心配な方</h3>
                                <p className="usecase__body t-p1 t-sans t-grey7">
                                    1) 濃厚接触者ではないが、周囲で感染者や濃厚接触者が発生した<br/>
                                    2) 仕事で多くの人と接する機会が多いため心配<br/>
                                    3) 仕事などでPCR検査の陰性証明書が必要な方、団体・企業様
                                </p>
                            </div>
                        </div>
                        <div className="usecase">
                            <img src="/img/features/case2.jpg" alt="use case" />
                            <div className="usecase__detail">
                                <h3 className="usecase__heading t-serif t-h3">家庭のために</h3>
                                <p className="usecase__body t-p1 t-sans t-grey7">
                                    1) 高齢・乳幼児の家族に会うのが心配<br/>
                                    2) 友人に会いに行く前に検査したい<br/>
                                    3) 自らの定期的なセルフチェック
                                </p>
                            </div>
                        </div>
                        <div className="usecase">
                            <img src="/img/features/case3.jpg" alt="use case" />
                            <div className="usecase__detail">
                                <h3 className="usecase__heading t-serif t-h3">人が集まる場所などへの移動</h3>
                                <p className="usecase__body t-p1 t-sans t-grey7">
                                1) 他府県に行く前に感染の有無を確認したい<br/>
                                2) 人が集まる機会に事前に確認しておきたい<br/>
                                3) 出張や帰省に合わせて確認したい
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="features__about features__about--grey">
                <div className="container">
                    <h2 className="features__about__heading t-h2 t-center">グループの医療機関での自費PCR検査</h2>
                    <div className="intros">
                        <p className="t-sans t-grey7 intro t-p1">医療施設内の臨床検査室でPCR検査を実施しております</p>
                        <p className="t-sans t-grey7 intro t-p1">地方自治体でも利用されているPCR検査です</p>
                        <p className="t-sans t-grey7 intro t-p1">検査室が実施する検査は、リアルタイムPCR法を用いてウイルス由来のRNAを分析し感染の有無を確認しています</p>
                        <p className="t-sans t-grey7 intro t-p1">自費診療ですので医師の診察がなくてもPCR検査を受けていただけます</p>
                    </div>
                </div>
            </section>
            <Footer />
        </MenuLayout>
    );
}